/* eslint-disable */
import { actionTypes as types } from '../constants';

const initialState = {
  fetching: false,
  fetched: false,
  result: {},
  requestStatus: false,
  error: false,
  mode: null,
};

export const getRulesResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_RULES_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_RULES_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_RULES_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getFraudAuthRulesResult = (state = initialState, action) => {

  switch (action.type) {
  case types.GET_FRAUD_AUTH_RULES_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_FRAUD_AUTH_RULES_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_FRAUD_AUTH_RULES_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getPreAuthRulesResult = (state = initialState, action) => {

  switch (action.type) {
  case types.GET_PRI_AUTH_RULES_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_PRI_AUTH_RULES_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_PRI_AUTH_RULES_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getPostAuthRulesResult = (state = initialState, action) => {

  switch (action.type) {
  case types.GET_POST_AUTH_RULES_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_POST_AUTH_RULES_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_POST_AUTH_RULES_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getfraudMetaDataResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_FRAUD_ROUTING_METADATA_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_FRAUD_ROUTING_METADATA_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_FRAUD_ROUTING_METADATA_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getRulesMetaDataResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_RULES_METADATA_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_RULES_METADATA_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_RULES_METADATA_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};


export const getDRRulesMetaDataResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_DR_RULES_METADATA_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_DR_RULES_METADATA_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_DR_RULES_METADATA_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getInstallmentRulesMetaDataResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_INSTALLMENT_RULES_METADATA_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_INSTALLMENT_RULES_METADATA_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_INSTALLMENT_RULES_METADATA_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getSurchargeMetaDataResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_SURCHARGE_RULES_METADATA_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_SURCHARGE_RULES_METADATA_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_SURCHARGE_RULES_METADATA_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const createRuleResult = (state = initialState, action) => {
  switch (action.type) {
  case types.CREATE_RULE_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.CREATE_RULE_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.CREATE_RULE_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const getRuleListResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_RULE_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_RULE_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_RULE_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const getFormMode = (state = initialState, action) => {
  switch (action.type) {
  case types.SET_FORM_MODE:
    return {
      ...state, result: action.data,
    };
  default:
    return state;
  }
};
export const updateRuleResult = (state = initialState, action) => {
  switch (action.type) {
  case types.UPDATE_RULE_REQUEST:
    return {
      ...state, fetching: true, fetched: false, error: false, result: {},
    };
  case types.UPDATE_RULE_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.UPDATE_RULE_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const deleteRuleResult = (state = initialState, action) => {
  switch (action.type) {
  case types.DELETE_RULE_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.DELETE_RULE_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.DELETE_RULE_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getClientConfigDataResult = (state = initialState, action) => {
  switch (action.type) {
  case types.CLIENT_CONFIG_DATA_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.CLIENT_CONFIG_DATA_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.CLIENT_CONFIG_DATA_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getCustomerTypeResult = (state = initialState, action) => {
  switch (action.type) {
  case types.CUSTOMER_TYPE_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.CUSTOMER_TYPE_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.CUSTOMER_TYPE_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const getSubscriptionResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_SUBSCRIPTION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_SUBSCRIPTION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_SUBSCRIPTION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const getBinRangeMetaDataResult = (state = initialState, action) => {
  switch (action.type) {
  case types.BINRANGE_METADATA_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.BINRANGE_METADATA_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.BINRANGE_METADATA_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const updateManageSubscriptionResult = (state = initialState, action) => {
  switch (action.type) {
  case types.UPDATE_MERCHANT_SUBSCRIPTION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.UPDATE_MERCHANT_SUBSCRIPTION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.UPDATE_MERCHANT_SUBSCRIPTION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const configureRouteResult = (state = initialState, action) => {
  switch (action.type) {
  case types.CONFIGURE_ROUTE_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.CONFIGURE_ROUTE_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.CONFIGURE_ROUTE_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const getRouteResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_ROUTE_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_ROUTE_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_ROUTE_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const getRouteMetaDataResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_ROUTE_METADATA_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_ROUTE_METADATA_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_ROUTE_METADATA_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const getBinRangeSearchResult = (state = initialState, action) => {
  switch (action.type) {
  case types.BINRANGE_SEARCH_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.BINRANGE_SEARCH_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.BINRANGE_SEARCH_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const getIataCodeResult = (state = initialState, action) => {
  switch (action.type) {
  case types.IATA_CODE_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.IATA_CODE_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.IATA_CODE_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const getRouteDetailsResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_ROUTE_DETAILS_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_ROUTE_DETAILS_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_ROUTE_DETAILS_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const updateRouteResult = (state = initialState, action) => {
  switch (action.type) {
  case types.UPDATE_ROUTE_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.UPDATE_ROUTE_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.UPDATE_ROUTE_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const deleteRouteResult = (state = initialState, action) => {
  switch (action.type) {
  case types.DELETE_ROUTE_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.DELETE_ROUTE_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.DELETE_ROUTE_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const getActiveRuleResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_ACTIVE_RULE_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_ACTIVE_RULE_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_ACTIVE_RULE_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const deleteActiveRuleResult = (state = initialState, action) => {
  switch (action.type) {
  case types.DELETE_ACTIVE_RULE_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.DELETE_ACTIVE_RULE_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.DELETE_ACTIVE_RULE_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const staticRouteRidirect = (state = initialState, action) => {
  switch (action.type) {
  case types.STATIC_ROUTE_REDIRECT:
    return {
      ...state, mode: action.data,
    };
  default:
    return state;
  }
};
