export const DASHBOARD_REQUEST = 'DASHBOARD_REQUEST';
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS';
export const DASHBOARD_FAILURE = 'DASHBOARD_FAILURE';
export const DASHBOARD_FILTER = 'DASHBOARD_FILTER';

export const CLIENT_CONFIG_REQUEST = 'CLIENT_CONFIG_REQUEST';
export const CLIENT_CONFIG_SUCCESS = 'CLIENT_CONFIG_SUCCESS';
export const CLIENT_CONFIG_FAILURE = 'CLIENT_CONFIG_FAILURE';

export const SYSTEM_META_DATA_REQUEST = 'SYSTEM_META_DATA_REQUEST';
export const SYSTEM_META_DATA_SUCCESS = 'SYSTEM_META_DATA_SUCCESS';
export const SYSTEM_META_DATA_FAILURE = 'SYSTEM_META_DATA_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const RESET_PASSWORD_TOKEN_REQUEST = 'RESET_PASSWORD_TOKEN_REQUEST';
export const RESET_PASSWORD_TOKEN_SUCCESS = 'RESET_PASSWORD_TOKEN_SUCCESS';
export const RESET_PASSWORD_TOKEN_FAILURE = 'RESET_PASSWORD_TOKEN_FAILURE';

export const PERMISSIONS_REQUEST = 'PERMISSIONS_REQUEST';
export const PERMISSIONS_SUCCESS = 'PERMISSIONS_SUCCESS';
export const PERMISSIONS_FAILURE = 'PERMISSIONS_FAILURE';

export const LOCATION_REQUEST = 'LOCATION_REQUEST';
export const LOCATION_SUCCESS = 'LOCATION_SUCCESS';
export const LOCATION_FAILURE = 'LOCATION_FAILURE';

export const LIST_LOCATION_REQUEST = 'LIST_LOCATION_REQUEST';
export const LIST_LOCATION_SUCCESS = 'LIST_LOCATION_SUCCESS';
export const LIST_LOCATION_FAILURE = 'LIST_LOCATION_FAILURE';

export const ADD_LOCATION_REQUEST = 'ADD_LOCATION_REQUEST';
export const ADD_LOCATION_SUCCESS = 'ADD_LOCATION_SUCCESS';
export const ADD_LOCATION_FAILURE = 'ADD_LOCATION_FAILURE';

export const PROMOTIONLIST_REQUEST = 'PROMOTIONLIST_REQUEST';
export const PROMOTIONLIST_SUCCESS = 'PROMOTIONLIST_SUCCESS';
export const PROMOTIONLIST_FAILURE = 'PROMOTIONLIST_FAILURE';

export const TRIGGERLIST_REQUEST = 'TRIGGERLIST_REQUEST';
export const TRIGGERLIST_SUCCESS = 'TRIGGERLIST_SUCCESS';
export const TRIGGERLIST_FAILURE = 'TRIGGERLIST_FAILURE';

export const MANUALTRIGGERLIST_REQUEST = 'MANUALTRIGGERLIST_REQUEST';
export const MANUALTRIGGERLIST_SUCCESS = 'MANUALTRIGGERLIST_SUCCESS';
export const MANUALTRIGGERLIST_FAILURE = 'MANUALTRIGGERLIST_FAILURE';

export const ORDERSUMMARY_REQUEST = 'ORDERSUMMARY_REQUEST';
export const ORDERSUMMARY_SUCCESS = 'ORDERSUMMARY_SUCCESS';
export const ORDERSUMMARY_FAILURE = 'ORDERSUMMARY_FAILURE';

export const CONFIGURE_MESSAGE_REQUEST = 'CONFIGURE_MESSAGE_REQUEST';
export const CONFIGURE_MESSAGE_SUCCESS = 'CONFIGURE_MESSAGE_SUCCESS';
export const CONFIGURE_MESSAGE_FAILURE = 'CONFIGURE_MESSAGE_FAILURE';

export const PREVIEW_PUBLISH_REQUEST = 'PREVIEW_PUBLISH_REQUEST';
export const PREVIEW_PUBLISH_SUCCESS = 'PREVIEW_PUBLISH_SUCCESS';
export const PREVIEW_PUBLISH_FAILURE = 'PREVIEW_PUBLISH_FAILURE';

export const SCHEDULE_REQUEST = 'SCHEDULE_REQUEST';
export const SCHEDULE_SUCCESS = 'SCHEDULE_SUCCESS';
export const SCHEDULE_FAILURE = 'SCHEDULE_FAILURE';

export const DELETE_REQUEST = 'DELETE_REQUEST';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_FAILURE = 'DELETE_FAILURE';

export const TRIGGER_EDIT_REQUEST = 'TRIGGER_EDIT_REQUEST';
export const TRIGGER_EDIT_SUCCESS = 'TRIGGER_EDIT_SUCCESS';
export const TRIGGER_EDIT_FAILURE = 'TRIGGER_EDIT_FAILURE';

export const AUTOTRIGGERLIST_REQUEST = 'AUTOTRIGGERLIST_REQUEST';
export const AUTOTRIGGERLIST_SUCCESS = 'AUTOTRIGGERLIST_SUCCESS';
export const AUTOTRIGGERLIST_FAILURE = 'AUTOTRIGGERLIST_FAILURE';

export const CONFIGUREAUTOTRIGGER_REQUEST = 'CONFIGUREAUTOTRIGGER_REQUEST';
export const CONFIGUREAUTOTRIGGER_SUCCESS = 'CONFIGUREAUTOTRIGGER_SUCCESS';
export const CONFIGUREAUTOTRIGGER_FAILURE = 'CONFIGUREAUTOTRIGGER_FAILURE';

export const DELETEAUTOTRIGGER_REQUEST = 'DELETEAUTOTRIGGER_REQUEST';
export const DELETEAUTOTRIGGER_SUCCESS = 'DELETEAUTOTRIGGER_SUCCESS';
export const DELETEAUTOTRIGGER_FAILURE = 'DELETEAUTOTRIGGER_FAILURE';

export const RETRIEVEAUTOTRIGGER_REQUEST = 'RETRIEVEAUTOTRIGGER_REQUEST';
export const RETRIEVEAUTOTRIGGER_SUCCESS = 'RETRIEVEAUTOTRIGGER_SUCCESS';
export const RETRIEVEAUTOTRIGGER_FAILURE = 'RETRIEVEAUTOTRIGGER_FAILURE';


// Reports
export const SEARCHREPORTS_REQUEST = 'SEARCHREPORTS_REQUEST';
export const SEARCHREPORTS_SUCCESS = 'SEARCHREPORTS_SUCCESS';
export const SEARCHREPORTS_FAILURE = 'SEARCHREPORTS_FAILURE';

// View Reports
export const VIEWREPORTS_REQUEST = 'VIEWREPORTS_REQUEST';
export const VIEWREPORTS_SUCCESS = 'VIEWREPORTS_SUCCESS';
export const VIEWREPORTS_FAILURE = 'VIEWREPORTS_FAILURE';

export const DOWNLOAD_REPORT_REQUEST = 'DOWNLOAD_REPORT_REQUEST';
export const DOWNLOAD_REPORT_SUCCESS = 'DOWNLOAD_REPORT_SUCCESS';
export const DOWNLOAD_REPORT_FAILURE = 'DOWNLOAD_REPORT_FAILURE';

export const DOWNLOAD_REPORT_URL_REQUEST = 'DOWNLOAD_REPORT_URL_REQUEST';
export const DOWNLOAD_REPORT_URL_SUCCESS = 'DOWNLOAD_REPORT_URL_SUCCESS';
export const DOWNLOAD_REPORT_URL_FAILURE = 'DOWNLOAD_REPORT_URL_FAILURE';
export const DOWNLOAD_REPORT_URL = 'DOWNLOAD_REPORT_URL';

export const EMAIL_REPORT_REQUEST = 'EMAIL_REPORT_REQUEST';
export const EMAIL_REPORT_SUCCESS = 'EMAIL_REPORT_SUCCESS';
export const EMAIL_REPORT_FAILURE = 'EMAIL_REPORT_FAILURE';

// Ibe configs--------------
export const IBECONFIGURATIONS_REQUEST = 'IBECONFIGURATIONS_REQUEST';
export const IBECONFIGURATIONS_SUCCESS = 'IBECONFIGURATIONS_SUCCESS';
export const IBECONFIGURATIONS_FAILURE = 'IBECONFIGURATIONS_FAILURE';

// IBE update configs-----------
export const IBECONFIGURATIONS_UPDATE_REQUEST = 'IBECONFIGURATIONS_UPDATE_REQUEST';
export const IBECONFIGURATIONS_UPDATE_SUCCESS = 'IBECONFIGURATIONS_UPDATE_SUCCESS';
export const IBECONFIGURATIONS_UPDATE_FAILURE = 'IBECONFIGURATIONS_UPDATE_FAILURE';

export const PROMOTIONCREATE_REQUEST = 'PROMOTIONCREATE_REQUEST';
export const PROMOTIONCREATE_SUCCESS = 'PROMOTIONCREATE_SUCCESS';
export const PROMOTIONCREATE_FAILURE = 'PROMOTIONCREATE_FAILURE';

export const PROMOTIONUPDATE_REQUEST = 'PROMOTIONUPDATE_REQUEST';
export const PROMOTIONUPDATE_SUCCESS = 'PROMOTIONUPDATE_SUCCESS';
export const PROMOTIONUPDATE_FAILURE = 'PROMOTIONUPDATE_FAILURE';

export const PROMOTIONDELETE_REQUEST = 'PROMOTIONDELETE_REQUEST';
export const PROMOTIONDELETE_SUCCESS = 'PROMOTIONDELETE_SUCCESS';
export const PROMOTIONDELETE_FAILURE = 'PROMOTIONDELETE_FAILURE';

export const PROMOTIONVIEW_REQUEST = 'PROMOTIONVIEW_REQUEST';
export const PROMOTIONVIEW_SUCCESS = 'PROMOTIONVIEW_SUCCESS';
export const PROMOTIONVIEW_FAILURE = 'PROMOTIONVIEW_FAILURE';


/*
**  Events Constants
*/
export const CHANGE_EVENT_NAME = 'CHANGE_EVENT_NAME';
export const CHANGE_EVENT_DESCRIPTION = 'CHANGE_EVENT_DESCRIPTION';
export const CHANGE_EVENT_EXPIRYDATE = 'CHANGE_EVENT_EXPIRYDATE';
export const RESET_EVENT_CONFIGURE_FORM = 'RESET_EVENT_CONFIGURE_FORM';
// Event Create Request
export const EVENTCREATE_REQUEST = 'EVENTCREATE_REQUEST';
export const EVENTCREATE_SUCCESS = 'EVENTCREATE_SUCCESS';
export const EVENTCREATE_FAILURE = 'EVENTCREATE_FAILURE';
// Event Update Request
export const EVENTUPDATE_REQUEST = 'EVENTUPDATE_REQUEST';
export const EVENTUPDATE_SUCCESS = 'EVENTUPDATE_SUCCESS';
export const EVENTUPDATE_FAILURE = 'EVENTUPDATE_FAILURE';
// Event View Request
export const EVENTVIEW_REQUEST = 'EVENTVIEW_REQUEST';
export const EVENTVIEW_SUCCESS = 'EVENTVIEW_SUCCESS';
export const EVENTVIEW_FAILURE = 'EVENTVIEW_FAILURE';
// Event Edit Request
export const EVENTEDIT_REQUEST = 'EVENTEDIT_REQUEST';
export const EVENTEDIT_SUCCESS = 'EVENTEDIT_SUCCESS';
export const EVENTEDIT_FAILURE = 'EVENTEDIT_FAILURE';
export const RESET_EVENT_CONFIGURE_EDIT_FORM = 'RESET_EVENT_CONFIGURE_EDIT_FORM';
// Set event data
export const SET_VIEW_EVENT = 'SET_VIEW_EVENT';
export const CLEAR_VIEW_EVENT = 'CLEAR_VIEW_EVENT';


// TOKEN BASED LOGIN
export const TOKEN_LOGIN_REQUEST = 'TOKEN_LOGIN_REQUEST';
export const TOKEN_LOGIN_SUCCESS = 'TOKEN_LOGIN_SUCCESS';
export const TOKEN_LOGIN_FAILURE = 'TOKEN_LOGIN_FAILURE';

// TOKEN BASED LOGIN
export const TOPICLIST_REQUEST = 'TOPICLIST_REQUEST';
export const TOPICLIST_SUCCESS = 'TOPICLIST_SUCCESS';
export const TOPICLIST_FAILURE = 'TOPICLIST_FAILURE';

// Broadcast
export const BROADCAST_REQUEST = 'BROADCAST_REQUEST';
export const BROADCAST_SUCCESS = 'BROADCAST_SUCCESS';
export const BROADCAST_FAILURE = 'BROADCAST_FAILURE';

export const BROADCAST_CREATE_REQUEST = 'BROADCAST_CREATE_REQUEST';
export const BROADCAST_CREATE_SUCCESS = 'BROADCAST_CREATE_SUCCESS';
export const BROADCAST_CREATE_FAILURE = 'BROADCAST_CREATE_FAILURE';

export const BROADCAST_DELETE_REQUEST = 'BROADCAST_DELETE_REQUEST';
export const BROADCAST_DELETE_SUCCESS = 'BROADCAST_DELETE_SUCCESS';
export const BROADCAST_DELETE_FAILURE = 'BROADCAST_DELETE_FAILURE';

export const BROADCAST_EDIT_REQUEST = 'BROADCAST_EDIT_REQUEST';
export const BROADCAST_EDIT_SUCCESS = 'BROADCAST_EDIT_SUCCESS';
export const BROADCAST_EDIT_FAILURE = 'BROADCAST_EDIT_FAILURE';


// Set promotion data
export const SET_VIEW_PROMOTION = 'SET_VIEW_PROMOTION';
export const CLEAR_VIEW_PROMOTION = 'SET_VIEW_PROMOTION';

// Get Aws Object
export const GET_AWSOBJECT_REQUEST = 'GET_AWSOBJECT_REQUEST';
export const GET_AWSOBJECT_SUCCESS = 'GET_AWSOBJECT_SUCCESS';
export const GET_AWSOBJECT_FAILURE = 'GET_AWSOBJECT_FAILURE';

// SET PERMISSION OBJECT
export const SET_PERMISSION_OBJECT = 'SET_PERMISSION_OBJECT';


// Subscribe Report
export const GET_MYSUBSCRIPTIONS_REQUEST = 'GET_MYSUBSCRIPTIONS_REQUEST';
export const GET_MYSUBSCRIPTIONS_SUCCESS = 'GET_MYSUBSCRIPTIONS_SUCCESS';
export const GET_MYSUBSCRIPTIONS_FAILURE = 'GET_MYSUBSCRIPTIONS_FAILURE';

export const GET_SUBSCRIBE_REQUEST = 'GET_SUBSCRIBE_REQUEST';
export const GET_SUBSCRIBE_SUCCESS = 'GET_SUBSCRIBE_SUCCESS';
export const GET_SUBSCRIBE_FAILURE = 'GET_SUBSCRIBE_FAILURE';

export const SUBSCRIBE_REPORT_REQUEST = 'SUBSCRIBE_REPORT_REQUEST';
export const SUBSCRIBE_REPORT_SUCCESS = 'SUBSCRIBE_REPORT_SUCCESS';
export const SUBSCRIBE_REPORT_FAILURE = 'SUBSCRIBE_REPORT_FAILURE';

export const UPDATE_SUBSCRIPTION_REQUEST = 'UPDATE_SUBSCRIPTION_REQUEST';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_FAILURE = 'UPDATE_SUBSCRIPTION_FAILURE';

export const UNSUBSCRIBE_REQUEST = 'UNSUBSCRIBE_REQUEST';
export const UNSUBSCRIBE_SUCCESS = 'UNSUBSCRIBE_SUCCESS';
export const UNSUBSCRIBE_FAILURE = 'UNSUBSCRIBE_FAILURE';

export const SAVE_FILTER_REQUEST = 'SAVE_FILTER_REQUEST';
export const SAVE_FILTER_SUCCESS = 'SAVE_FILTER_SUCCESS';
export const SAVE_FILTER_FAILURE = 'SAVE_FILTER_FAILURE';

export const GET_FILTER_REQUEST = 'GET_FILTER_REQUEST';
export const GET_FILTER_SUCCESS = 'GET_FILTER_SUCCESS';
export const GET_FILTER_FAILURE = 'GET_FILTER_FAILURE';

export const UPDATE_FILTER_REQUEST = 'UPDATE_FILTER_REQUEST';
export const UPDATE_FILTER_SUCCESS = 'UPDATE_FILTER_SUCCESS';
export const UPDATE_FILTER_FAILURE = 'UPDATE_FILTER_FAILURE';

export const DELETE_FILTER_REQUEST = 'DELETE_FILTER_REQUEST';
export const DELETE_FILTER_SUCCESS = 'DELETE_FILTER_SUCCESS';
export const DELETE_FILTER_FAILURE = 'DELETE_FILTER_FAILURE';

export const SET_SESSION_EXPIRED = 'SET_SESSION_EXPIRED';
export const CLEAR_SESSION_EXPIRED = 'CLEAR_SESSION_EXPIRED';
// MANAGE TRANSACTIONS

export const MANAGE_TRANSACTION_REQUEST = 'MANAGE_TRANSACTION_REQUEST';
export const MANAGE_TRANSACTION_SUCCESS = 'MANAGE_TRANSACTION_SUCCESS';
export const MANAGE_TRANSACTION_FAILURE = 'MANAGE_TRANSACTION_FAILURE';

export const REFUND_LINES_TRANSACTION_REQUEST = 'REFUND_LINES_TRANSACTION_REQUEST';
export const REFUND_LINES_TRANSACTION_SUCCESS = 'REFUND_LINES_TRANSACTION_SUCCESS';
export const REFUND_LINES_TRANSACTION_FAILURE = 'REFUND_LINES_TRANSACTION_FAILURE';

export const MANAGE_TRANSACTION_DOWNLOAD_REQUEST = 'MANAGE_TRANSACTION_DOWNLOAD_REQUEST';
export const MANAGE_TRANSACTION_DOWNLOAD_SUCCESS = 'MANAGE_TRANSACTION_DOWNLOAD_SUCCESS';
export const MANAGE_TRANSACTION_DOWNLOAD_FAILURE = 'MANAGE_TRANSACTION_DOWNLOAD_FAILURE';
export const MANAGE_TRANSACTION_DOWNLOAD_CLEAR = 'MANAGE_TRANSACTION_DOWNLOAD_CLEAR';


export const EMAIL_TRANSACTION_REPORT_REQUEST = 'EMAIL_TRANSACTION_REPORT_REQUEST';
export const EMAIL_TRANSACTION_REPORT_SUCCESS = 'EMAIL_TRANSACTION_REPORT_SUCCESS';
export const EMAIL_TRANSACTION_REPORT_FAILURE = 'EMAIL_TRANSACTION_REPORT_FAILURE';

export const VIEW_TRANSACTION_REQUEST = 'VIEW_TRANSACTION_REQUEST';
export const VIEW_TRANSACTION_SUCCESS = 'VIEW_TRANSACTION_SUCCESS';
export const VIEW_TRANSACTION_FAILURE = 'VIEW_TRANSACTION_FAILURE';

export const ADD_TRANSACTION_NOTE_REQUEST = 'ADD_TRANSACTION_NOTE_REQUEST';
export const ADD_TRANSACTION_NOTE_SUCCESS = 'ADD_TRANSACTION_NOTE_SUCCESS';
export const ADD_TRANSACTION_NOTE_FAILURE = 'ADD_TRANSACTION_NOTE_FAILURE';

export const CAPTURE_REFUND_TRANSACTION_REQUEST = 'CAPTURE_REFUND_TRANSACTION_REQUEST';
export const CAPTURE_REFUND_TRANSACTION_SUCCESS = 'CAPTURE_REFUND_TRANSACTION_SUCCESS';
export const CAPTURE_REFUND_TRANSACTION_FAILURE = 'CAPTURE_REFUND_TRANSACTION_FAILURE';

export const UPDATE_STATUS_REVIEW_REQUEST = 'UPDATE_STATUS_REVIEW_REQUEST';
export const UPDATE_STATUS_REVIEW_SUCCESS = 'UPDATE_STATUS_REVIEW_SUCCESS';
export const UPDATE_STATUS_REVIEW_FAILURE = 'UPDATE_STATUS_REVIEW_FAILURE';

// Geofence
export const CREATE_GEOFENCE_REQUEST = 'CREATE_GEOFENCE_REQUEST';
export const CREATE_GEOFENCE_SUCCESS = 'CREATE_GEOFENCE_SUCCESS';
export const CREATE_GEOFENCE_FAILURE = 'CREATE_GEOFENCE_FAILURE';

export const DELETE_GEOFENCE_REQUEST = 'DELETE_GEOFENCE_REQUEST';
export const DELETE_GEOFENCE_SUCCESS = 'DELETE_GEOFENCE_SUCCESS';
export const DELETE_GEOFENCE_FAILURE = 'DELETE_GEOFENCE_FAILURE';

export const SEARCH_GEOFENCE_REQUEST = 'SEARCH_GEOFENCE_REQUEST';
export const SEARCH_GEOFENCE_SUCCESS = 'SEARCH_GEOFENCE_SUCCESS';
export const SEARCH_GEOFENCE_FAILURE = 'SEARCH_GEOFENCE_FAILURE';
export const VIEW_GEOFENCE_REQUEST = 'VIEW_GEOFENCE_REQUEST';
export const VIEW_GEOFENCE_SUCCESS = 'VIEW_GEOFENCE_SUCCESS';
export const VIEW_GEOFENCE_FAILURE = 'VIEW_GEOFENCE_FAILURE';


export const SET_GEOFENCE = 'SET_GEOFENCE';
export const CLEAR_GEOFENCE = 'CLEAR_GEOFENCE';


// Notification
export const CREATE_NOTIFICATION_REQUEST = 'CREATE_NOTIFICATION_REQUEST';
export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS';
export const CREATE_NOTIFICATION_FAILURE = 'CREATE_NOTIFICATION_FAILURE';

export const UPDATE_NOTIFICATION_REQUEST = 'UPDATE_NOTIFICATION_REQUEST';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_FAILURE = 'UPDATE_NOTIFICATION_FAILURE';


export const SCHEDULE_NOTIFICATION_REQUEST = 'SCHEDULE_NOTIFICATION_REQUEST';
export const SCHEDULE_NOTIFICATION_SUCCESS = 'SCHEDULE_NOTIFICATION_SUCCESS';
export const SCHEDULE_NOTIFICATION_FAILURE = 'SCHEDULE_NOTIFICATION_FAILURE';


export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE';

// SET_NOTIFICATION_DATA
export const SET_NOTIFICATION_DATA = 'SET_NOTIFICATION_DATA';
export const CLEAR_NOTIFICATION_DATA = 'CLEAR_NOTIFICATION_DATA';

export const GET_NOTIFICATION_METADATA_REQUEST = 'GET_NOTIFICATION_METADATA_REQUEST';
export const GET_NOTIFICATION_METADATA_SUCCESS = 'GET_NOTIFICATION_METADATA_SUCCESS';
export const GET_NOTIFICATION_METADATA_FAILURE = 'GET_NOTIFICATION_METADATA_FAILURE';

// GET SYNCHRONIZED AWSOBJECT
export const GET_SYNCHRONIZED_AWSOBJECT_REQUEST = 'GET_SYNCHRONIZED_AWSOBJECT_REQUEST';
export const GET_SYNCHRONIZED_AWSOBJECT_SUCCESS = 'GET_SYNCHRONIZED_AWSOBJECT_SUCCESS';
export const GET_SYNCHRONIZED_AWSOBJECT_FAILURE = 'GET_SYNCHRONIZED_AWSOBJECT_FAILURE';

// Screen Configurations
export const GET_SCREENS_REQUEST = 'GET_SCREENS_REQUEST';
export const GET_SCREENS_SUCCESS = 'GET_SCREENS_SUCCESS';
export const GET_SCREENS_FAILURE = 'GET_SCREENS_FAILURE';

export const DELETE_SCREEN_REQUEST = 'DELETE_SCREEN_REQUEST';
export const DELETE_SCREEN_SUCCESS = 'DELETE_SCREEN_SUCCESS';
export const DELETE_SCREEN_FAILURE = 'DELETE_SCREEN_FAILURE';

export const CREATE_SCREEN_REQUEST = 'CREATE_SCREEN_REQUEST';
export const CREATE_SCREEN_SUCCESS = 'CREATE_SCREEN_SUCCESS';
export const CREATE_SCREEN_FAILURE = 'CREATE_SCREEN_FAILURE';

export const GET_ALLNOTIFICATIONS_REQUEST = 'GET_ALLNOTIFICATIONS_REQUEST';
export const GET_ALLNOTIFICATIONS_SUCCESS = 'GET_ALLNOTIFICATIONS_SUCCESS';
export const GET_ALLNOTIFICATIONS_FAILURE = 'GET_ALLNOTIFICATIONS_FAILURE';
// Clone storefront

export const SET_CLONE_STOREFRONT = 'SET_CLONE_STOREFRONT';
export const CLEAR_STOREFRONT = 'CLEAR_STOREFRONT';

// Routing Configurations
export const GET_RULES_REQUEST = 'GET_RULES_REQUEST';
export const GET_RULES_SUCCESS = 'GET_RULES_SUCCESS';
export const GET_RULES_FAILURE = 'GET_RULES_FAILURE';

// Routing FRAUD auth  Configurations
export const GET_FRAUD_AUTH_RULES_REQUEST = 'GET_FRAUD_AUTH_RULES_REQUEST';
export const GET_FRAUD_AUTH_RULES_SUCCESS = 'GET_FRAUD_AUTH_RULES_SUCCESS';
export const GET_FRAUD_AUTH_RULES_FAILURE = 'GET_FRAUD_AUTH_RULES_FAILURE';

export const GET_PRI_AUTH_RULES_REQUEST = 'GET_PRI_AUTH_RULES_REQUEST ';
export const GET_PRI_AUTH_RULES_SUCCESS = 'GET_PRI_AUTH_RULES_SUCCESS';
export const GET_PRI_AUTH_RULES_FAILURE = 'GET_PRI_AUTH_RULES_FAILURE';

export const GET_POST_AUTH_RULES_REQUEST = 'GET_POST_AUTH_RULES_REQUEST';
export const GET_POST_AUTH_RULES_SUCCESS = 'GET_POST_AUTH_RULES_SUCCESS';
export const GET_POST_AUTH_RULES_FAILURE = 'GET_POST_AUTH_RULES_FAILURE';

// FX Configurations
export const GET_PROVIDERS_REQUEST = 'GET_PROVIDERS_REQUEST';
export const GET_PROVIDERS_SUCCESS = 'GET_PROVIDERS_SUCCESS';
export const GET_PROVIDERS_FAILURE = 'GET_PROVIDERS_FAILURE';

// ADD FX Configurations
export const ADD_FX_CONFIG_REQUEST = 'ADD_FX_CONFIG_REQUEST';
export const ADD_FX_CONFIG_SUCCESS = 'ADD_FX_CONFIG_SUCCESS';
export const ADD_FX_CONFIG_FAILURE = 'ADD_FX_CONFIG_FAILURE';

// ADD FX Configurations
export const UPDATE_FX_CONFIG_REQUEST = 'UPDATE_FX_CONFIG_REQUEST';
export const UPDATE_FX_CONFIG_SUCCESS = 'UPDATE_FX_CONFIG_SUCCESS';
export const UPDATE_FX_CONFIG_FAILURE = 'UPDATE_FX_CONFIG_FAILURE';

// GET FX Configurations
export const GET_FX_CONFIG_REQUEST = 'GET_FX_CONFIG_REQUEST';
export const GET_FX_CONFIG_SUCCESS = 'GET_FX_CONFIG_SUCCESS';
export const GET_FX_CONFIG_FAILURE = 'GET_FX_CONFIG_FAILURE';

// KPI Dashbaords
export const KPI_DASHBOARD_REQUEST = 'KPI_DASHBOARD_REQUEST';
export const KPI_DASHBOARD_SUCCESS = 'KPI_DASHBOARD_SUCCESS';
export const KPI_DASHBOARD_FAILURE = 'KPI_DASHBOARD_FAILURE';


// Routing Configurations
export const GET_FRAUD_ROUTING_METADATA_REQUEST = 'GET_FRAUD_ROUTING_METADATA_REQUEST';
export const GET_FRAUD_ROUTING_METADATA_SUCCESS = 'GET_FRAUD_ROUTING_METADATA_SUCCESS';
export const GET_FRAUD_ROUTING_METADATA_FAILURE = 'GET_FRAUD_ROUTING_METADATA_FAILURE';

export const GET_RULES_METADATA_REQUEST = 'GET_RULES_METADATA_REQUEST';
export const GET_RULES_METADATA_SUCCESS = 'GET_RULES_METADATA_SUCCESS';
export const GET_RULES_METADATA_FAILURE = 'GET_RULES_METADATA_FAILURE';

export const GET_DR_RULES_METADATA_REQUEST = 'GET_DR_RULES_METADATA_REQUEST';
export const GET_DR_RULES_METADATA_SUCCESS = 'GET_DR_RULES_METADATA_SUCCESS';
export const GET_DR_RULES_METADATA_FAILURE = 'GET_DR_RULES_METADATA_FAILURE';

export const GET_INSTALLMENT_RULES_METADATA_REQUEST = 'GET_INSTALLMENT_RULES_METADATA_REQUEST';
export const GET_INSTALLMENT_RULES_METADATA_SUCCESS = 'GET_INSTALLMENT_RULES_METADATA_SUCCESS';
export const GET_INSTALLMENT_RULES_METADATA_FAILURE = 'GET_INSTALLMENT_RULES_METADATA_FAILURE';

export const GET_SURCHARGE_RULES_METADATA_REQUEST = 'GET_SURCHARGE_RULES_METADATA_REQUEST';
export const GET_SURCHARGE_RULES_METADATA_SUCCESS = 'GET_SURCHARGE_RULES_METADATA_SUCCESS';
export const GET_SURCHARGE_RULES_METADATA_FAILURE = 'GET_SURCHARGE_RULES_METADATA_FAILURE';

export const CREATE_RULE_REQUEST = 'CREATE_RULE_REQUEST';
export const CREATE_RULE_SUCCESS = 'CREATE_RULE_SUCCESS';
export const CREATE_RULE_FAILURE = 'CREATE_RULE_FAILURE';

export const GET_RULE_REQUEST = 'GET_RULE_REQUEST';
export const GET_RULE_SUCCESS = 'GET_RULE_SUCCESS';
export const GET_RULE_FAILURE = 'GET_RULE_FAILURE';

export const SET_FORM_MODE = 'SET_FORM_MODE';

export const UPDATE_RULE_REQUEST = 'UPDATE_RULE_REQUEST';
export const UPDATE_RULE_SUCCESS = 'UPDATE_RULE_SUCCESS';
export const UPDATE_RULE_FAILURE = 'UPDATE_RULE_FAILURE';

export const DELETE_RULE_REQUEST = 'DELETE_RULE_REQUEST';
export const DELETE_RULE_SUCCESS = 'DELETE_RULE_SUCCESS';
export const DELETE_RULE_FAILURE = 'DELETE_RULE_FAILURE';

export const CLIENT_CONFIG_DATA_REQUEST = 'CLIENT_CONFIG_DATA_REQUEST';
export const CLIENT_CONFIG_DATA_SUCCESS = 'CLIENT_CONFIG_DATA_SUCCESS';
export const CLIENT_CONFIG_DATA_FAILURE = 'CLIENT_CONFIG_DATA_FAILURE';


export const CUSTOMER_TYPE_REQUEST = 'CUSTOMER_TYPE_REQUEST';
export const CUSTOMER_TYPE_SUCCESS = 'CUSTOMER_TYPE_SUCCESS';
export const CUSTOMER_TYPE_FAILURE = 'CUSTOMER_TYPE_FAILURE';

// SET_NOTIFICATION_DATA
export const SET_ATTRIBUTE_DATA = 'SET_ATTRIBUTE_DATA';
export const CLEAR_ATTRIBUTE_DATA = 'CLEAR_ATTRIBUTE_DATA';

export const GET_SUBSCRIPTION_REQUEST = 'GET_SUBSCRIPTION_REQUEST';
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_FAILURE = 'GET_SUBSCRIPTION_FAILURE';

export const UPDATE_MERCHANT_SUBSCRIPTION_REQUEST = 'UPDATE_MERCHANT_SUBSCRIPTION_REQUEST';
export const UPDATE_MERCHANT_SUBSCRIPTION_SUCCESS = 'UPDATE_MERCHANTSUBSCRIPTION_SUCCESS';
export const UPDATE_MERCHANT_SUBSCRIPTION_FAILURE = 'UPDATE_MERCHANT_SUBSCRIPTION_FAILURE';

export const CONFIGURE_ROUTE_REQUEST = 'CONFIGURE_ROUTE_REQUEST';
export const CONFIGURE_ROUTE_SUCCESS = 'CONFIGURE_ROUTE_SUCCESS';
export const CONFIGURE_ROUTE_FAILURE = 'CONFIGURE_ROUTE_FAILURE';

export const UPDATE_ROUTE_REQUEST = 'UPDATE_ROUTE_REQUEST';
export const UPDATE_ROUTE_SUCCESS = 'UPDATE_ROUTE_SUCCESS';
export const UPDATE_ROUTE_FAILURE = 'UPDATE_ROUTE_FAILURE';

export const GET_ROUTE_REQUEST = 'GET_ROUTE_REQUEST';
export const GET_ROUTE_SUCCESS = 'GET_ROUTE_SUCCESS';
export const GET_ROUTE_FAILURE = 'GET_ROUTE_FAILURE';

export const GET_ROUTE_METADATA_REQUEST = 'GET_ROUTE_METADATA_REQUEST';
export const GET_ROUTE_METADATA_SUCCESS = 'GET_ROUTE_METADATA_SUCCESS';
export const GET_ROUTE_METADATA_FAILURE = 'GET_ROUTE_METADATA_FAILURE';
// Bin Range
export const BINRANGE_METADATA_REQUEST = 'BINRANGE_METADATA_REQUEST';
export const BINRANGE_METADATA_SUCCESS = 'BINRANGE_METADATA_SUCCESS';
export const BINRANGE_METADATA_FAILURE = 'BINRANGE_METADATA_FAILURE';

export const BINRANGE_SEARCH_REQUEST = 'BINRANGE_SEARCH_REQUEST';
export const BINRANGE_SEARCH_SUCCESS = 'BINRANGE_SEARCH_SUCCESS';
export const BINRANGE_SEARCH_FAILURE = 'BINRANGE_SEARCH_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

// profile section
export const PROFILE_GET_REQUEST = 'PROFILE_GET_REQUEST';
export const PROFILE_GET_SUCCESS = 'PROFILE_GET_SUCCESS';
export const PROFILE_GET_FAILURE = 'PROFILE_GET_FAILURE';

export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAILURE = 'PROFILE_UPDATE_FAILURE';

// Iata Code
export const IATA_CODE_REQUEST = 'IATA_CODE_REQUEST';
export const IATA_CODE_SUCCESS = 'IATA_CODE_SUCCESS';
export const IATA_CODE_FAILURE = 'IATA_CODE_FAILURE';

export const GET_ROUTE_DETAILS_REQUEST = 'GET_ROUTE_DETAILS_REQUEST';
export const GET_ROUTE_DETAILS_SUCCESS = 'GET_ROUTE_DETAILS_SUCCESS';
export const GET_ROUTE_DETAILS_FAILURE = 'GET_ROUTE_DETAILS_FAILURE';


export const DELETE_ROUTE_REQUEST = 'DELETE_ROUTE_REQUEST';
export const DELETE_ROUTE_SUCCESS = 'DELETE_ROUTE_SUCCESS';
export const DELETE_ROUTE_FAILURE = 'DELETE_ROUTE_FAILURE';

export const GET_ACTIVE_RULE_REQUEST = 'GET_ACTIVE_RULE_REQUEST';
export const GET_ACTIVE_RULE_SUCCESS = 'GET_ACTIVE_RULE_SUCCESS';
export const GET_ACTIVE_RULE_FAILURE = 'GET_ACTIVE_RULE_FAILURE';

export const DELETE_ACTIVE_RULE_REQUEST = 'DELETE_ACTIVE_RULE_REQUEST';
export const DELETE_ACTIVE_RULE_SUCCESS = 'DELETE_ACTIVE_RULE_SUCCESS';
export const DELETE_ACTIVE_RULE_FAILURE = 'DELETE_ACTIVE_RULE_FAILURE';

export const STATIC_ROUTE_REDIRECT = 'STATIC_ROUTE_REDIRECT';

// Event Notification Request data
export const GET_ALL_FILTER_CONFIGURATIONS_REQUEST = 'GET_ALL_FILTER_CONFIGURATIONS_REQUEST';
export const GET_ALL_FILTER_CONFIGURATIONS_SUCCESS = 'GET_ALL_FILTER_CONFIGURATIONS_SUCCESS';
export const GET_ALL_FILTER_CONFIGURATIONS_FAILURE = 'GET_ALL_FILTER_CONFIGURATIONS_FAILURE';

export const GET_ALL_KEYS_REQUEST = 'GET_ALL_KEYS_REQUEST';
export const GET_ALL_KEYS_SUCCESS = 'GET_ALL_KEYS_SUCCESS';
export const GET_ALL_KEYS_FAILURE = 'GET_ALL_KEYS_FAILURE';

export const GET_ALL_QUEUES_REQUEST = 'GET_ALL_QUEUES_REQUEST';
export const GET_ALL_QUEUES_SUCCESS = 'GET_ALL_QUEUES_SUCCESS';
export const GET_ALL_QUEUES_FAILURE = 'GET_ALL_QUEUES_FAILURE';

export const GET_ALL_FILTERS_REQUEST = 'GET_ALL_FILTERS_REQUEST';
export const GET_ALL_FILTERS_SUCCESS = 'GET_ALL_FILTERS_SUCCESS';
export const GET_ALL_FILTERS_FAILURE = 'GET_ALL_FILTERS_FAILURE';

export const CREATE_FILTER_CONFIGURATION_REQUEST = 'CREATE_FILTER_CONFIGURATION_REQUEST';
export const CREATE_FILTER_CONFIGURATION_SUCCESS = 'CREATE_FILTER_CONFIGURATION_SUCCESS';
export const CREATE_FILTER_CONFIGURATION_FAILURE = 'CREATE_FILTER_CONFIGURATION_FAILURE';

export const DELETE_FILTER_CONFIGURATION_REQUEST = 'DELETE_FILTER_CONFIGURATION_REQUEST';
export const DELETE_FILTER_CONFIGURATION_SUCCESS = 'DELETE_FILTER_CONFIGURATION_SUCCESS';
export const DELETE_FILTER_CONFIGURATION_FAILURE = 'DELETE_FILTER_CONFIGURATION_FAILURE';

export const GET_FILTER_CONFIG_REQUEST = 'GET_FILTER_CONFIG_REQUEST';
export const GET_FILTER_CONFIG_SUCCESS = 'GET_FILTER_CONFIG_SUCCESS';
export const GET_FILTER_CONFIG_FAILURE = 'GET_FILTER_CONFIG_FAILURE';

export const UPDATE_FILTER_CONFIG_REQUEST = 'UPDATE_FILTER_CONFIG_REQUEST';
export const UPDATE_FILTER_CONFIG_SUCCESS = 'UPDATE_FILTER_CONFIG_SUCCESS';
export const UPDATE_FILTER_CONFIG_FAILURE = 'UPDATE_FILTER_CONFIG_FAILURE';

// CPL
export const PAYMENTLINK_GETMETADATA_REQUEST = 'PAYMENTLINK_GETMETADATA_REQUEST';
export const PAYMENTLINK_GETMETADATA_SUCCESS = 'PAYMENTLINK_GETMETADATA_SUCCESS';
export const PAYMENTLINK_GETMETADATA_FAILURE = 'PAYMENTLINK_GETMETADATA_FAILURE';

export const CREATE_PAYMENTLINK_REQUEST = 'CREATE_PAYMENTLINK_REQUEST';
export const CREATE_PAYMENTLINK_SUCCESS = 'CREATE_PAYMENTLINK_SUCCESS';
export const CREATE_PAYMENTLINK_FAILURE = 'CREATE_PAYMENTLINK_FAILURE';

export const GET_PAYMENTLINKS_REQUEST = 'GET_PAYMENTLINKS_REQUEST';
export const GET_PAYMENTLINKS_SUCCESS = 'GET_PAYMENTLINKS_SUCCESS';
export const GET_PAYMENTLINKS_FAILURE = 'GET_PAYMENTLINKS_FAILURE';

export const DELETE_PAYMENTLINK_REQUEST = 'DELETE_PAYMENTLINK_REQUEST';
export const DELETE_PAYMENTLINK_SUCCESS = 'DELETE_PAYMENTLINK_SUCCESS';
export const DELETE_PAYMENTLINK_FAILURE = 'DELETE_PAYMENTLINK_FAILURE';

export const GET_PAYMENTLINKFILE_REQUEST = 'GET_PAYMENTLINKFILE_REQUEST';
export const GET_PAYMENTLINKFILE_SUCCESS = 'GET_PAYMENTLINKFILE_SUCCESS';
export const GET_PAYMENTLINKFILE_FAILURE = 'GET_PAYMENTLINKFILE_FAILURE';

export const GET_PAYMENTLINK_REQUEST = 'GET_PAYMENTLINK_REQUEST';
export const GET_PAYMENTLINK_SUCCESS = 'GET_PAYMENTLINK_SUCCESS';
export const GET_PAYMENTLINK_FAILURE = 'GET_PAYMENTLINK_FAILURE';

// SET_MT_DOWNLOAD_STATUS
export const MT_DOWNLOAD_STATUS = 'MT_DOWNLOAD_STATUS';

export const GET_SYSTEM_METADATA_REQUEST = 'GET_SYSTEM_METADATA_REQUEST';
export const GET_SYSTEM_METADATA_SUCCESS = 'GET_SYSTEM_METADATA_SUCCESS';
export const GET_SYSTEM_METADATA_FAILURE = 'GET_SYSTEM_METADATA_FAILURE';

export const GET_PAYMENT_METADATA_REQUEST = 'GET_PAYMENT_METADATA_REQUEST';
export const GET_PAYMENT_METADATA_SUCCESS = 'GET_PAYMENT_METADATA_SUCCESS';
export const GET_PAYMENT_METADATA_FAILURE = 'GET_PAYMENT_METADATA_FAILURE';

export const GET_CLIENT_CONFIGURATION_REQUEST = 'GET_CLIENT_CONFIGURATION_REQUEST';
export const GET_CLIENT_CONFIGURATION_SUCCESS = 'GET_CLIENT_CONFIGURATION_SUCCESS';
export const GET_CLIENT_CONFIGURATION_FAILURE = 'GET_CLIENT_CONFIGURATION_FAILURE';

export const POST_CLIENT_CONFIGURATION_REQUEST = 'POST_CLIENT_CONFIGURATION_REQUEST';
export const POST_CLIENT_CONFIGURATION_SUCCESS = 'POST_CLIENT_CONFIGURATION_SUCCESS';
export const POST_CLIENT_CONFIGURATION_FAILURE = 'POST_CLIENT_CONFIGURATION_FAILURE';

export const DELETE_CLIENT_CONFIGURATION_REQUEST = 'DELETE_CLIENT_CONFIGURATION_REQUEST';
export const DELETE_CLIENT_CONFIGURATION_SUCCESS = 'DELETE_CLIENT_CONFIGURATION_SUCCESS';
export const DELETE_CLIENT_CONFIGURATION_FAILURE = 'DELETE_CLIENT_CONFIGURATION_FAILURE';

export const UPDATE_CLIENT_CONFIGURATION_REQUEST = 'UPDATE_CLIENT_CONFIGURATION_REQUEST';
export const UPDATE_CLIENT_CONFIGURATION_SUCCESS = 'UPDATE_CLIENT_CONFIGURATION_SUCCESS';
export const UPDATE_CLIENT_CONFIGURATION_FAILURE = 'UPDATE_CLIENT_CONFIGURATION_FAILURE';

export const GET_ADDON_CONFIGURATION_REQUEST = 'GET_ADDON_CONFIGURATION_REQUEST';
export const GET_ADDON_CONFIGURATION_SUCCESS = 'GET_ADDON_CONFIGURATION_SUCCESS';
export const GET_ADDON_CONFIGURATION_FAILURE = 'GET_ADDON_CONFIGURATION_FAILURE';

export const DELETE_ADDON_CONFIGURATION_REQUEST = 'DELETE_ADDON_CONFIGURATION_REQUEST';
export const DELETE_ADDON_CONFIGURATION_SUCCESS = 'DELETE_ADDON_CONFIGURATION_SUCCESS';
export const DELETE_ADDON_CONFIGURATION_FAILURE = 'DELETE_ADDON_CONFIGURATION_FAILURE';

export const POST_ADDON_CONFIGURATION_REQUEST = 'POST_ADDON_CONFIGURATION_REQUEST';
export const POST_ADDON_CONFIGURATION_SUCCESS = 'POST_ADDON_CONFIGURATION_SUCCESS';
export const POST_ADDON_CONFIGURATION_FAILURE = 'POST_ADDON_CONFIGURATION_FAILURE';

export const GET_ALL_CONFIGURATION_FOR_FX_REQUEST = 'GET_ALL_CONFIGURATION_FOR_FX_REQUEST';
export const GET_ALL_CONFIGURATION_FOR_FX_SUCCESS = 'GET_ALL_CONFIGURATION_FOR_FX_SUCCESS';
export const GET_ALL_CONFIGURATION_FOR_FX_FAILURE = 'GET_ALL_CONFIGURATION_FOR_FX_FAILURE';

export const GET_PROVIDER_CONFIGURATION_REQUEST = 'GET_PROVIDER_CONFIGURATION_REQUEST';
export const GET_PROVIDER_CONFIGURATION_SUCCESS = 'GET_PROVIDER_CONFIGURATION_SUCCESS';
export const GET_PROVIDER_CONFIGURATION_FAILURE = 'GET_PROVIDER_CONFIGURATION_FAILURE';

export const POST_PROVIDER_CONFIGURATION_REQUEST = 'POST_PROVIDER_CONFIGURATION_REQUEST';
export const POST_PROVIDER_CONFIGURATION_SUCCESS = 'POST_PROVIDER_CONFIGURATION_SUCCESS';
export const POST_PROVIDER_CONFIGURATION_FAILURE = 'POST_PROVIDER_CONFIGURATION_FAILURE';

export const DELETE_PROVIDER_CONFIGURATION_REQUEST = 'DELETE_PROVIDER_CONFIGURATION_REQUEST';
export const DELETE_PROVIDER_CONFIGURATION_SUCCESS = 'DELETE_PROVIDER_CONFIGURATION_SUCCESS';
export const DELETE_PROVIDER_CONFIGURATION_FAILURE = 'DELETE_PROVIDER_CONFIGURATION_FAILURE';

export const CREATE_CLIENT_CONFIGURATION_REQUEST = 'CREATE_CLIENT_CONFIGURATION_REQUEST';
export const CREATE_CLIENT_CONFIGURATION_SUCCESS = 'CREATE_CLIENT_CONFIGURATION_SUCCESS';
export const CREATE_CLIENT_CONFIGURATION_FAILURE = 'CREATE_CLIENT_CONFIGURATION_FAILURE';

export const GET_PSP_CONFIGURATION_REQUEST = 'GET_PSP_CONFIGURATION_REQUEST';
export const GET_PSP_CONFIGURATION_SUCCESS = 'GET_PSP_CONFIGURATION_SUCCESS';
export const GET_PSP_CONFIGURATION_FAILURE = 'GET_PSP_CONFIGURATION_FAILURE';

export const UPDATE_PSP_CONFIGURATION_REQUEST = 'UPDATE_PSP_CONFIGURATION_REQUEST';
export const UPDATE_PSP_CONFIGURATION_SUCCESS = 'UPDATE_PSP_CONFIGURATION_SUCCESS';
export const UPDATE_PSP_CONFIGURATION_FAILURE = 'UPDATE_PSP_CONFIGURATION_FAILURE';

export const GET_ROUTE_CONFIGURATION_REQUEST = 'GET_ROUTE_CONFIGURATION_REQUEST';
export const GET_ROUTE_CONFIGURATION_SUCCESS = 'GET_ROUTE_CONFIGURATION_SUCCESS';
export const GET_ROUTE_CONFIGURATION_FAILURE = 'GET_ROUTE_CONFIGURATION_FAILURE';

export const GET_ROUTE_PSP_CONFIGURATION_REQUEST = 'GET_ROUTE_PSP_CONFIGURATION_REQUEST';
export const GET_ROUTE_PSP_CONFIGURATION_SUCCESS = 'GET_ROUTE_PSP_CONFIGURATION_SUCCESS';
export const GET_ROUTE_PSP_CONFIGURATION_FAILURE = 'GET_ROUTE_PSP_CONFIGURATION_FAILURE';

export const UPDATE_ROUTE_PSP_CONFIGURATION_REQUEST = 'UPDATE_ROUTE_PSP_CONFIGURATION_REQUEST';
export const UPDATE_ROUTE_PSP_CONFIGURATION_SUCCESS = 'UPDATE_ROUTE_PSP_CONFIGURATION_SUCCESS';
export const UPDATE_ROUTE_PSP_CONFIGURATION_FAILURE = 'UPDATE_ROUTE_PSP_CONFIGURATION_FAILURE';

export const DELETE_PSP_CONFIGURATION_REQUEST = 'DELETE_PSP_CONFIGURATION_REQUEST';
export const DELETE_PSP_CONFIGURATION_SUCCESS = 'DELETE_PSP_CONFIGURATION_SUCCESS';
export const DELETE_PSP_CONFIGURATION_FAILURE = 'DELETE_PSP_CONFIGURATION_FAILURE';

export const GET_PSP_CONFIGURATION_BY_ID_REQUEST = 'GET_PSP_CONFIGURATION_BY_ID_REQUEST';
export const GET_PSP_CONFIGURATION_BY_ID_SUCCESS = 'GET_PSP_CONFIGURATION_BY_ID__SUCCESS';
export const GET_PSP_CONFIGURATION_BY_ID_FAILURE = 'GET_PSP_CONFIGURATION_BY_ID__FAILURE';

export const DELETE_ROUTE_PSP_CONFIGURATION_REQUEST = 'DELETE_ROUTE_PSP_CONFIGURATION_REQUEST';
export const DELETE_ROUTE_PSP_CONFIGURATION_SUCCESS = 'DELETE_ROUTE_PSP_CONFIGURATION_SUCCESS';
export const DELETE_ROUTE_PSP_CONFIGURATION_FAILURE = 'DELETE_ROUTE_PSP_CONFIGURATION_FAILURE';

export const GET_ROUTE_PSP_CONFIGURATION_BY_ID_REQUEST = 'GET_ROUTE_PSP_CONFIGURATION_BY_ID_REQUEST';
export const GET_ROUTE_PSP_CONFIGURATION_BY_ID_SUCCESS = 'GET_ROUTE_PSP_CONFIGURATION_BY_ID_SUCCESS';
export const GET_ROUTE_PSP_CONFIGURATION_BY_ID_FAILURE = 'GET_ROUTE_PSP_CONFIGURATION_BY_ID_FAILURE';

export const UPDATE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_REQUEST = 'UPDATE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_REQUEST';
export const UPDATE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_SUCCESS = 'UPDATE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_SUCCESS';
export const UPDATE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_FAILURE = 'UPDATE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_FAILURE';

export const DELETE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_REQUEST = 'DELETE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_REQUEST';
export const DELETE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_SUCCESS = 'DELETE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_SUCCESS';
export const DELETE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_FAILURE = 'DELETE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_FAILURE';

export const GET_ALL_ADDITIONAL_FILTERS_REQUEST = 'GET_ALL_ADDITIONAL_FILTERS_REQUEST';
export const GET_ALL_ADDITIONAL_FILTERS_SUCCESS = 'GET_ALL_ADDITIONAL_FILTERS_SUCCESS';
export const GET_ALL_ADDITIONAL_FILTERS_FAILURE = 'GET_ALL_ADDITIONAL_FILTERS_FAILURE';

export const POST_UPDATE_ADDITIONAL_FILTERS_REQUEST = 'POST_UPDATE_ADDITIONAL_FILTERS_REQUEST';
export const POST_UPDATE_ADDITIONAL_FILTERS_SUCCESS = 'POST_UPDATE_ADDITIONAL_FILTERS_SUCCESS';
export const POST_UPDATE_ADDITIONAL_FILTERS_FAILURE = 'POST_UPDATE_ADDITIONAL_FILTERS_FAILURE';

export const POST_ADDITIONAL_FILTERS_REQUEST = 'POST_ADDITIONAL_FILTERS_REQUEST';
export const POST_ADDITIONAL_FILTERS_SUCCESS = 'POST_ADDITIONAL_FILTERS_SUCCESS';
export const POST_ADDITIONAL_FILTERS_FAILURE = 'POST_ADDITIONAL_FILTERS_FAILURE';
