
const getData = item => {
  const permissionsSet = localStorage.getItem('permissionSet');
  return (permissionsSet && permissionsSet.toString().includes(item)) || false;
};

export const permissionsObj = () => ({
  menu: {
    dashboards: {
      icon: getData('mconsole.dashboard.menu.'),
      transactions: getData('mconsole.dashboard.menu.payment.x'),
      revenue: getData('mconsole.dashboard.menu.revenue.x'),
      product: getData('mconsole.dashboard.menu.product.x'),
      KPIDashboard: false,
      profile: false,
      promotion: getData('mconsole.dashboard.menu.promotion.x'),
      notification: getData('mconsole.dashboard.menu.notification.x'),
      fraudcheck: getData('mconsole.dashboard.menu.fraudcheck.x'),
    },
    velocity: {
      icon: getData('mconsole.velocity.menu.'),
      payByLink: getData('mconsole.velocity.menu.pay by link.x'),
      autoTrigger: getData('mconsole.velocity.menu.auto trigger.x'),
      dynamicRouting: getData('mconsole.velocity.menu.dynamic routing.x'),
      fop: getData('mconsole.velocity.menu.dynamic routing.x'),
      fms: getData('mconsole.velocity.menu.dynamic routing.x'),
      installments: getData('mconsole.velocity.menu.installments.x'),
      fx: getData('mconsole.velocity.menu.FX.x'),
      hostedPaymentPage: getData('mconsole.velocity.menu.hosted payment page.x'),
      merchantOnboardingLocale: getData('mconsole.velocity.menu.merchant onboarding.x'),
      configureServices: getData('mconsole.velocity.menu.configure services.x'),
      travelBank: getData('mconsole.velocity.menu.travelbank.x'),
      paymentLink: getData('mconsole.velocity.menu.paymentLink.x'),
      preAuthFraudCreate: getData('routing.preAuthFraud.create.x'), // - can create pre fraud
      preAuthFraudRead: getData('routing.preAuthFraud.r'), // - can read  pre fraud
      preAuthFraudEdit: getData('routing.preAuthFraud.w'), // - can edit or delete  pre fraud
      postAuthFraudCreate: getData('routing.postAuthFraud.create.x'), // - can create post fraud
      postAuthFraudRead: getData('routing.postAuthFraud.r'), // - can read  post fraud
      postAuthFraudEdit: getData('routing.postAuthFraud.w'), // - can edit or delete  post fraud
      surchargeCreate: getData('routing.surcharge.create.x'), // - can create surcharge
      surchargeView: getData('routing.surcharge.r'), // - can read  surcharge
      surchargeEdit: getData('routing.surcharge.w'), // - can edit or delete surcharge
    },
    vantage: {
      icon: getData('mconsole.vantage.menu.'),
      promotionsAndEvents: getData('mconsole.vantage.menu.promotions-event.x'),
      topics: getData('mconsole.vantage.menu.topics.x'),
      geofence: getData('mconsole.vantage.menu.geofence.x'),
    },
    reports: {
      icon: getData('mconsole.reports.menu.'),
      viewReports: getData('mconsole.reports.menu.view reports.x'),
      mySubscriptions: getData('mconsole.reports.menu.view reports.x'),
      manageTransaction: getData('mconsole.reports.menu.view manage transactions.x'),
      viewIcon: getData('mconsole.reports.disable.view.w'),
      subscriptionIcon: getData('mconsole.reports.disable.subscription.w'),
      downloadIcon: getData('mconsole.reports.disable.download.w'),
    },
    notification: {
      icon: getData('mconsole.notification.menu.'),
      screenConfigurations: getData('mconsole.notification.menu.notification engine.x'),
      notificationEngine: getData('mconsole.notification.menu.notification engine.x'),
    },
    console: {
      icon: getData('mconsole.console.menu.'),
      merchantOnBoarding: getData('mconsole.console.menu.merchant onboarding.x'),
      eventNotification: getData('mconsole.console.menu.event notification.x'),

    },
  },
  actions: {
    promotions: {
      create: getData('mconsole.vantage.promotion.create.x'),
      view: getData('mconsole.vantage.promotion.r'),
      edit: getData('mconsole.vantage.promotion.w'),
    },
    events: {
      create: getData('mconsole.vantage.event.create.x'),
      view: getData('mconsole.vantage.event.r'),
      edit: getData('mconsole.vantage.event.w'),
    },
    dynamicBanners: {
      create: getData('mconsole.vantage.dynamicbanner.create.x'),
      view: getData('mconsole.vantage.dynamicbanner.r'),
      edit: getData('mconsole.vantage.dynamicbanner.w'),
    },
    geoFence: {
      create: getData('mconsole.vantage.geofence.create.x'),
      view: getData('mconsole.vantage.geofence.r'),
      edit: getData('mconsole.vantage.geofence.w'),
    },
    merchantOnBoarding: {
      create: getData('mconsole.console.merchant onboarding.create.x'),
      view: getData('mconsole.console.merchant onboarding.r'),
      edit: getData('mconsole.console.merchant onboarding.w'),
      delete: getData('mpoint.delete-route-configuration.x'),
    },
    notificationEngine: {
      smartNotifyCheckBox: getData('mconsole.notification.smartnotify.x'),
    },
    dynamicRoutingPaymentRule: {
      create: getData('mconsole.velocity.dynamic routing.paymentRule.create.x'),
      view: getData('mconsole.velocity.dynamic routing.paymentRule.r'),
      edit: getData('mconsole.velocity.dynamic routing.paymentRule.w'),
      additionalData: getData('mconsole.velocity.dynamic routing.additional data'),
    },
    dynamicRoutingFopRule: {
      create: getData('mconsole.velocity.dynamic routing.fopRule.create.x'),
      view: getData('mconsole.velocity.dynamic routing.fopRule.r'),
      edit: getData('mconsole.velocity.dynamic routing.fopRule.w'),
    },
    installment: {
      create: getData('mconsole.velocity.installments.create.x'),
      view: getData('mconsole.velocity.installments.r'),
      edit: getData('mconsole.velocity.installments.w'),
    },
    manageTransaction: {
      view: getData('mconsole.reports.managetransactions.r'),
      edit: getData('mconsole.reports.managetransactions.w'),
    },
    eventNotification: {
      create: getData('mconsole.console.event notification.create.x'),
      view: getData('mconsole.console.event notification.r'),
      edit: getData('mconsole.console.event notification.w'),
    },
    paymentLink: {
      create: getData('mconsole.velocity.paymentLink.create.x'),
      view: getData('mconsole.velocity.paymentLink.r'),
      edit: getData('mconsole.velocity.paymentLink.w'),
      download: getData('mconsole.velocity.paymentLink.download.w'),
    },
  },
});
